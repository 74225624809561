import skins from 'constants/skins';
import scopes from 'constants/scopes';
import { contextRoutes } from 'constants/contextRoutes';

export const getContextRoutes = ({ planId = undefined }) => {
  return contextRoutes[planId];
};

export const getAccessRoutes = ({
  planId = undefined,
  scopeId = undefined,
  skinId = undefined,
}) => {
  if (!planId && !scopeId && !skinId) return [];

  const internalRoutes = [
    '/gerenciar-empresas',
    '/consultar-acessos',
    '/gerenciar-implantacao',
    '/conciliacao-retroativa',
    '/radar',
    '/regras-conciliacao',
    '/regras-ofx',
  ];

  const { accessRoutes = [] } = getContextRoutes({ planId });

  // TODO: Change validation based on plan, skin, scope and profile to API
  if (scopeId !== scopes.CONCIL && skinId === skins.CONCIL_REVENDA) {
    const restrictedRoutes = [...internalRoutes, '/planos'];

    return accessRoutes?.filter((route) => !restrictedRoutes.includes(route));
  }

  if (scopeId !== scopes.CONCIL) {
    return accessRoutes?.filter((route) => !internalRoutes.includes(route));
  }

  return accessRoutes;
};

export const getDefaultRoute = ({ planId = undefined }) => {
  if (!planId) return '';
  const { defaultRoute = '' } = getContextRoutes({ planId });
  return defaultRoute;
};
