import { lambdaApi } from 'services/api';

export const getSummary = async ({
  page = 1,
  perPage = 10,
  startDate,
  endDate,
  sortFields = {},
  q = { filter: [] },
}) => {
  const config = {
    params: {
      page,
      per_page: perPage,
      start_date: startDate,
      end_date: endDate,
      sort_fields: sortFields,
      q: JSON.stringify(q),
    },
  };

  const result = await lambdaApi().get('/onboarding/summary', config);

  return result.data;
};
