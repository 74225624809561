import { useCallback, useEffect, useState } from 'react';
import { Form } from 'antd';
import getListOfPlansFromAPI from 'services/listOfPlans';
import errorHandler from 'helpers/errorHandler';
import { getStatus } from 'services/onboardingManagement/getStatus';
import { getSummary } from 'services/onboardingManagement/getSummary';

export const useFiltersForm = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [changeCalendarIconColor, setChangeCalendarIconColor] = useState(false);
  const [plans, setPlans] = useState([{}]);
  const [status, setStatus] = useState([]);
  const [data, setData] = useState([{}]);

  const initialValues = useCallback(() => {
    return {
      date: ['', ''],
      codClient: '',
      stage: '',
      plan: '',
      status: '',
    };
  }, []);

  const stageOptions = [
    {
      id: 1,
      value: false,
      label: 'Implantação',
    },
    {
      id: 2,
      value: true,
      label: 'Produção',
    },
  ];

  const rules = {
    date: {
      required: true,
      async validator(_, value) {
        await new Promise((resolve, reject) => {
          if (value === null || value === undefined) {
            setChangeCalendarIconColor(true);
            reject(new Error('Campo obrigatório'));
          } else {
            setChangeCalendarIconColor(false);
            resolve();
          }
        });
      },
    },
    codClient: {
      async validator() {
        await new Promise((resolve, reject) => {
          if (form.getFieldError('codClient').length > 0) {
            reject(new Error(form.getFieldError('codClient')[0]));
          } else {
            resolve();
          }
        });
      },
    },
  };

  const getPlans = useCallback(async () => {
    try {
      const { plans: apiPlans } = await getListOfPlansFromAPI();
      setPlans(apiPlans);
    } catch (error) {
      setPlans([{}]);
      errorHandler('Ocorreu um erro:', error);
    }
  }, []);

  const getOnboardingStatus = useCallback(async () => {
    try {
      const values = await getStatus();
      setStatus(values);
    } catch (error) {
      setStatus([]);
      errorHandler('Ocorreu um erro:', error);
    }
  }, []);

  const handleFormValues = (obj) => {
    let result = {};

    Object.entries(obj).forEach(([key, value]) => {
      if (value && value !== '') {
        result = {
          ...result,
          [key]: value,
        };
      }

      if (key === 'stage' && value === false) {
        result = {
          ...result,
          [key]: value,
        };
      }
    });

    return result;
  };

  const handleFilterValues = useCallback(
    ({ codClient = '', plan = '', status: _status = '', stage = '' }) => {
      const result = [];

      if (codClient) {
        result.push({
          name: 'cod_client',
          val: [codClient],
        });
      }
      if (plan) {
        result.push({ name: 'plan', val: [plan] });
      }
      if (_status) {
        result.push({ name: 'status', val: [_status] });
      }
      if (stage !== undefined && stage !== '') {
        result.push({ name: 'stage', val: [stage] });
      }

      return result;
    },
    []
  );

  const getOnboardingSummary = useCallback(
    async (params) => {
      const formValues = params && handleFormValues(params);
      const filtersValues = params && handleFilterValues(params);

      setLoading(true);

      try {
        const result = await getSummary({
          page: formValues?.page || 1, // TODO: alterar quando tiver a tabela com a paginação
          perPage: formValues?.perPage || 10, // TODO: alterar quando tiver a tabela com a paginação
          startDate: formValues?.date[0].format('YYYY-MM-DD'),
          endDate: formValues?.date[1].format('YYYY-MM-DD'),
          q: { filter: filtersValues && filtersValues },
        });
        setData(result);
      } catch (error) {
        setData([{}]);

        if (error?.response?.data?.data?.detail === 'Número inválido') {
          form.setFields([
            {
              name: 'codClient',
              errors: [error?.response?.data?.data?.detail],
            },
          ]);
        }
      } finally {
        setLoading(false);
      }
    },
    [form, handleFilterValues]
  );

  const onFinish = async (formValues) => {
    await getOnboardingSummary(handleFormValues(formValues));
  };

  const resetForm = () => {
    return form.resetFields();
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        await Promise.all([getPlans(), getOnboardingStatus()]);
      } catch (error) {
        errorHandler('Ocorreu um erro:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [getPlans, getOnboardingStatus]);

  return {
    form,
    rules,
    stageOptions,
    initialValues,
    resetForm,
    loading,
    setLoading,
    changeCalendarIconColor,
    setChangeCalendarIconColor,
    onFinish,
    plans,
    status,
    data,
  };
};
