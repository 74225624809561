import React, { createContext } from 'react';
import { Grid } from 'antd';
import { Filters } from './components/Filters/Filters';
import * as St from './styled';

export const OnboardingManagementContext = createContext({});

export function OnboardingManagement() {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();

  return (
    <OnboardingManagementContext.Provider value={{}}>
      <St.Container $breakpoint={breakpoint}>
        <Filters />
      </St.Container>
    </OnboardingManagementContext.Provider>
  );
}
